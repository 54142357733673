// CSS RESETS --------------------------------------------------
// https://www.digitalocean.com/community/tutorials/css-minimal-css-reset

html {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.15;
}

body {
    margin: 0;
    color: #3A3A3A;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: white;
    text-decoration: none;
}

button {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
}
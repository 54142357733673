@use "sass:math";
@use "../abstracts/variables" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/mixins" as *;

.footer {
	width: 100%;
	padding: 0 30px;
	background-color: $brand-black;

	.footer__container {
		margin: 0 auto;
		max-width: 1500px;
		height: 70px;
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $brand-white;

		font-weight: $fw-regular;
		letter-spacing: 0.5px;

		@media only screen and (max-width: 500px) {
			height: 80px;
			flex-direction: column-reverse;
			justify-content: center;

			p {
				font-size: rem(12);
				font-weight: $fw-regular;
				padding: 0;
				margin: 5px;
			}
		}

		.socials__container {
			position: unset;
			display: flex;
			padding-right: 0;

			@media only screen and (max-width: 500px) {
				padding: 0;
				svg {
					transform: scale(0.8);
				}
			}
		}
	}
}

@use "sass:math";
@use "../../abstracts/variables" as *;
@use "../../abstracts/functions" as *;
@use "../../abstracts/mixins" as *;

.susvic {
	.project__logo {
		width: clamp(350px, 50vw, 600px);
	}

	.quote {
		margin-top: $space-above;

		@media only screen and (max-width: 1000px) {
			// margin-top: 0;
		}
	}

	.project__image--large {
		margin-top: $space-above;
	}
}

$font-stack: "Lato", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-headings: "Norfolk Serif", serif;

$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-heavy: 800;
$fw-black: 900;
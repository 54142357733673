.main-container {
    height: calc(100vh - 150px);
    width: 100%;
    margin-top: 150px;
    overflow-x: hidden;

    @media only screen and (max-width: 1000px) {
        height: calc(100vh - 100px);
        margin-top: 100px;
    }

    @media only screen and (max-height: 700px) {
        height: calc(100vh - 100px);
        margin-top: 100px;
    }
}
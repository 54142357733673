@use "sass:math";
@use "../../abstracts/variables" as *;
@use "../../abstracts/functions" as *;
@use "../../abstracts/mixins" as *;

.djaliny {
	.margin-width {
		margin-top: 40px;
	}

	.quote p {
		@include reset-spacing;
	}

	.project {
		&__logo {
			width: clamp(300px, 50vw, 500px);
		}

		&__title-banner {
			grid-column: 2 / -2;
			text-align: center;
			margin-top: $space-above;

			p {
				line-height: 1em;
			}

			p:last-of-type {
				font-size: rem(32);
				margin-top: -1em;
			}
		}

		&__sponsors {
			grid-column: 2 / -2;
			margin-top: $space-above;

			div {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			@media only screen and (max-width: 1200px) {
				img {
					transform: scale(0.8);
				}
			}

			@media only screen and (max-width: 1000px) {
				text-align: center;
				margin-top: 0;
				margin-bottom: $space-below;

				div {
					width: 100%;
					display: grid;
					grid-template-columns: 1fr 1fr;
					justify-content: center;
					align-items: center;
				}

				img {
					justify-self: center;
					transform: scale(0.9);
				}
			}
		}

		&__persona-container {
			align-self: end;
			display: flex;
			flex-direction: column;
			gap: 50px;

			@media only screen and (max-width: 1000px) {
				margin-top: $space-between;
			}

			img {
				height: 200px;
				width: 200px;
				object-fit: cover;
				border-radius: 100%;
			}

			.quote {
				border: none;
				padding-left: unset;
			}

			.project__persona {
				display: flex;
				flex-direction: row;
				gap: 40px;

				&:last-of-type {
					flex-direction: row-reverse;
				}

				p {
					align-self: center;
				}

				@media only screen and (max-width: 1250px) {
					flex-direction: column;
					align-items: center;

					&:last-of-type {
						flex-direction: column;
					}
				}

				@media only screen and (max-width: 1000px) {
					flex-direction: row;
					align-items: flex-start;

					&:last-of-type {
						flex-direction: row-reverse;
					}
				}

				@media only screen and (max-width: 650px) {
					flex-direction: column;
					align-items: center;

					&:last-of-type {
						flex-direction: column;
					}
				}
			}
		}

		&__case-study {
			margin-bottom: $space-below;
		}
	}
}

@use "sass:math";
@use "../../abstracts/variables" as *;
@use "../../abstracts/functions" as *;
@use "../../abstracts/mixins" as *;

.mywarhammer {
	.project__logo {
		&--center {
			width: clamp(150px, 20vw, 300px);
		}

		&--left {
			width: clamp(120px, 20vw, 200px);
			justify-self: flex-start;
			margin-left: 10%;
		}

		&--right {
			width: clamp(120px, 20vw, 200px);
			justify-self: flex-end;
			margin-right: 10%;
		}

		@media only screen and (max-width: 500px) {
			&--left,
			&--right {
				display: none;
			}
		}
	}

	.project__image--large {
		grid-column: 2 / -1;

		img {
			object-fit: cover;
			max-height: 300px;
			width: 100%;
		}

		@media only screen and (max-width: 1500px) {
			grid-column: 1 / -1;
		}
	}

	.battle-honours img {
		object-position: 0 30%;
	}

	.citadel img {
		object-position: center;
	}

	.logo {
		margin-top: $space-above;
		grid-column: 2 / 6;
		width: 150px;
	}

	.landscape-swiper {
		margin-bottom: $space-below;
	}
}

@use "sass:math";
@use "../../abstracts/variables" as *;
@use "../../abstracts/functions" as *;
@use "../../abstracts/mixins" as *;

.staunton {
	.project__logo {
		width: clamp(350px, 50vw, 600px);
	}

	.project__hero {
		object-position: center bottom;
	}
}

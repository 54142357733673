@use "sass:math";
@use "../../abstracts/variables" as *;
@use "../../abstracts/functions" as *;
@use "../../abstracts/mixins" as *;

.project-banner__container {
	display: grid;
	grid-template-rows: 50px auto auto;
	height: calc(100vh - 150px);
	width: 100vw;
	overflow: hidden;

	@media only screen and (max-width: 999px) {
		display: none;
	}

	@media only screen and (max-height: 900px) {
		height: calc(100vh - 100px);
	}

}

.project-banner__navigation {
	grid-area: 2 / 1 / 3 / -1;
	z-index: 4;

	ul {
		width: 150%;
		transform: rotate(-8.93deg) scale(1);
		margin-left: -200px;

		@media only screen and (max-width: 2100px) {
			margin-left: -400px;
			transform: rotate(-8.93deg) scale(0.9);
		}

		@media only screen and (max-width: 1800px) {
			margin-left: -450px;
			width: 150%;
			transform: rotate(-8.93deg) scale(0.8);
		}

		@media only screen and (max-width: 1540px) {
			margin-left: -560px;
			width: 180%;
			transform: rotate(-8.93deg) scale(0.7);
		}

		@media only screen and (max-width: 1340px) {
			margin-left: -710px;
			width: 230%;
			transform: rotate(-8.93deg) scale(0.6);
		}

		@media only screen and (max-width: 1150px) {
			margin-left: -800px;
			width: 260%;
			transform: rotate(-8.93deg) scale(0.5);
		}


		li {
			overflow: hidden;
			display: grid;
			grid-template-columns: 300px max-content auto;
			align-items: center;
			gap: 50px;
			height: 260px;
			margin-top: -100px;

			font-family: $font-stack;
			font-weight: $fw-thin;
			font-size: 70px;
			letter-spacing: 10px;
			line-height: 0;
			text-transform: uppercase;
			text-align: left;

			@media only screen and (max-width: 1340px) {
				font-weight: $fw-light;
			}
		}
	}

	.project-banner__link-container {

		.rectangle {
			height: 80px;
			width: 100%;
			margin-top: 15px;
			background-color: $brand-pink;
			opacity: 0;
			transition: opacity 0.5s ease;
		}

		&.active {
			.link {
				font-family: $font-headings;
				text-transform: lowercase;
				color: $brand-pink;
				animation: changeFont 0.5s;
			}

			.rectangle {
				opacity: 1;
			}
		}

		.hidden {
			@media only screen and (min-width: 1800px) {
				display: inline-block;
			}
		}
	}
}

.project-banner__background-overlay {
	grid-area: 1 / 1 / -1 / -1;
	z-index: 3;

	background: linear-gradient(to right,
			rgba(255, 255, 255, 1) 12%,
			rgba(255, 255, 255, 1) 32%,
			rgba(255, 255, 255, 0.6) 69%,
			rgba(255, 255, 255, 0) 99%,
			rgba(255, 255, 255, 0) 100%);
}

.project-banner__background-image {
	grid-area: 1 / 1 / -1 / -1;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: 1;
	visibility: hidden;

	@media only screen and (min-height: 900px) {
		height: calc(100vh - 150px);
	}
}

#background-1 {
	background-image: url("./../../../images/project-mywarhammer/warhammer-header.jpg");
	background-position: center;
}

#background-2 {
	background-image: url("./../../../images/project-staunton/stauntonv4.jpg");
	background-position: right;
}

#background-3 {
	background-image: url("./../../../images/project-susvic/sustainabilityvic1.jpg");
	background-position: center;
}

#background-4 {
	background-image: url("./../../../images/project-djaliny/djaliny-1.jpg");
}

@keyframes changeFont {
	from {}

	1% {
		font-family: $font-headings;
		color: $brand-pink;
		text-transform: lowercase;
		line-height: 0px;
	}

	to {
		font-family: $font-headings;
		color: $brand-pink;
		text-transform: lowercase;
		line-height: 0px;
	}
}

@keyframes changeFontReverse {
	from {
		font-family: $font-headings;
		color: $brand-pink;
		text-transform: lowercase;
		line-height: 0px;
	}

	99% {
		font-family: $font-headings;
		color: $brand-pink;
		text-transform: lowercase;
		line-height: 0px;
	}

	to {}
}
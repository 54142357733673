@use "sass:math";
@use "../abstracts/variables" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/mixins" as *;

.bullets {
    ul {
        @include reset-spacing;
    }

    li {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 20px;
    }
}
@use "sass:math";
@use "../abstracts/variables" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/mixins" as *;

.socials__container {
	z-index: 5;
	position: absolute;
	bottom: 0;
	right: 0;
	width: max-content;
	height: max-content;

	display: flex;
	flex-direction: row;
	gap: 15px;
	align-items: center;
	padding: 15px 30px;

	@media only screen and (max-height: 900px) {
		display: none;
	}
}

.socials__icon {
	transition: {
		property: transform;
		duration: $timing;
		timing-function: $ease-std;
	}

	svg {
		height: auto;
		width: 30px;
		color: $brand-pink;
	}

	.iconify--brandico {
		height: 24px;
		padding-bottom: 2px;
	}

	.iconify--mingcute {
		height: 27px;
	}
}

.socials__icon:last-of-type {
	margin-left: -4px;
	margin-top: 2px;
}

.socials__icon:hover {
	transform: scale(1.2);
}

@media only screen and (max-width: 1000px) {
	.socials__container {
		display: none;
	}
}

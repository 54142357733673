@use "sass:math";
@use "../../abstracts/variables" as *;
@use "../../abstracts/functions" as *;
@use "../../abstracts/mixins" as *;

$path: "../../../fonts/";

@font-face {
    font-family: "Norfolk Serif";
    src: url('#{$path}NorfolkSerif-Regular.woff2') format('woff2');
    font-weight: $fw-regular;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('#{$path}Lato-Regular.woff2') format('woff2');
    font-weight: $fw-regular;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('#{$path}Lato-Italic.woff2') format('woff2');
    font-weight: $fw-regular;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-Thin.woff2') format('woff2');
    font-weight: $fw-thin;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-Light.woff2') format('woff2');
    font-weight: $fw-light;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-Medium.woff2') format('woff2');
    font-weight: $fw-medium;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-Semibold.woff2') format('woff2');
    font-weight: $fw-semibold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-SemiboldItalic.woff2') format('woff2');
    font-weight: $fw-semibold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-Bold.woff2') format('woff2');
    font-weight: $fw-bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-BoldItalic.woff2') format('woff2');
    font-weight: $fw-bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-Heavy.woff2') format('woff2');
    font-weight: $fw-heavy;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url('#{$path}Lato-Black.woff2') format('woff2');
    font-weight: $fw-black;
    font-style: normal;
    font-display: swap;
}